<template>
  <div class="padding24">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" >
      <a-form-model-item label="商品名称" prop="name">
        <a-input v-model="form.name" :maxLength="32" placeholder="商品名称">
          <div slot="addonAfter">{{ form.name.length }}/32</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="商品列表图" prop="coverImg">
        <UpdataImg UpdataImg @avatarfns="data => form.coverImg = data[0]" :imgUrl="form.coverImg ? [form.coverImg] : []" />
        <span>建议尺寸：194 * 194，图片大小不超过500k</span>
      </a-form-model-item>
      <a-form-model-item label="商品头图" prop="headImg" :wrapper-col="{ span: 18 }">
        <div v-if="draggable.coverBtn">
          <UpdataImg UpdataImg @avatarfns="data => form.headImg = data" :imageUrlNum="5" :imgUrl="form.headImg" />
          <a-button style="margin-top: 10px" type="primary" @click="draggable.coverBtn = false" :disabled="form.headImg.length === 0">重新排序</a-button>
        </div>
        <div v-else>
          <draggable v-model="form.headImg" :options="{animation:500}" class="listBox">
            <li v-for="(item, index) in form.headImg" :key="index" class="listClass">
              <img :src="item">
            </li>
          </draggable>
          <a-button style="margin-top: 10px;margin-left: 6px" @click="draggable.coverBtn = true">取消</a-button>
        </div>
        <span>建议尺寸：750*750，图片大小不超过500k，最多可添加5张头图，可拖动改变显示顺序</span>
      </a-form-model-item>
      <a-form-model-item label="商品描述" prop="description">
        <a-textarea v-model="form.description" placeholder="请输入内容，不超过200字" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="商品详情图" prop="detailsImg" :wrapper-col="{ span: 18 }">
        <div v-if="draggable.detailBtn">
          <UpdataImg UpdataImg @avatarfns="data => form.detailsImg = data" :imageUrlNum="3" :imgUrl="form.detailsImg"  :urlSize="1024" />
          <a-button style="margin-top: 10px" type="primary" @click="draggable.detailBtn = false" :disabled="form.detailsImg.length === 0">重新排序</a-button>
        </div>
        <div v-else>
          <draggable v-model="form.detailsImg" class="listBox" :options="{animation:500}">
            <li v-for="(item, index) in form.detailsImg" :key="index" class="listClass">
              <img :src="item">
            </li>
          </draggable>
          <a-button style="margin-top: 10px;margin-left: 6px" @click="draggable.detailBtn = true">取消</a-button>
        </div>
        <span>建议尺寸：750*750，图片大小不超过1M，最多可添加3张图，可拖动改变显示顺序</span>
      </a-form-model-item>
      <a-form-model-item label="商品分类" prop="categoryId" required>
        <a-select v-model="form.categoryIdOne" style="width: 150px;" @change="handleCategoryChange">
          <a-select-option v-for="(item,index) in firstTypes" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <a-select v-model="form.categoryId" style="width: 150px; margin-left: 10px">
          <a-select-option v-for="item in secondTypes" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="商品价格" prop="required_price" required>
        <a-radio-group class="sale-radio-group" v-model="form.saleType" @change="changeSaleType">
          <a-radio class="radio-item" :value="1">
            <span class="label-text">仅支持现金售卖</span>
            <a-input addonBefore="销售价" v-model.number="saleTypeObj['1'].price" style="width: 200px" @blur="changeSaleType" />
          </a-radio>
          <a-radio class="radio-item" :value="2">
            <span class="label-text">仅支持积分售卖</span>
            <a-input addonBefore="积分值" v-model.number="saleTypeObj['2'].creditPrice" style="width: 200px" @blur="changeSaleType" />
          </a-radio>
          <a-radio class="radio-item" :value="3">
            <span class="label-text">现金或积分售卖</span>
            <a-input addonBefore="销售价" v-model.number="saleTypeObj['3'].price" style="width: 200px" @blur="changeSaleType" />
            或
            <a-input addonBefore="积分值" v-model.number="saleTypeObj['3'].creditPrice" style="width: 200px" @blur="changeSaleType" />
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="商品库存" prop="stock">
        <a-input-number v-model="form.stock" :min="0" style="width: 150px" />
      </a-form-model-item>
      <a-form-model-item label="商品排序" prop="rank">
        <a-input-number v-model="form.rank" :min="0" placeholder="数字越大越靠前" style="width: 150px" />
      </a-form-model-item>
      <a-form-model-item label="是否实物" prop="virtual" required>
        <a-radio-group v-model="form.virtual">
          <a-radio :value="0"> 是 </a-radio>
          <a-radio :value="1"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否上架" prop="state" required>
        <a-radio-group v-model="form.state">
          <a-radio :value="1"> 是 </a-radio>
          <a-radio :value="2"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

    <FooterToolBar :collapsed="sideCollapsed">
			<a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
			<a-button type="primary" @click="handleSave">保存</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import FooterToolBar from '@/components/FooterToolbar'
import { baseMixin } from '@/store/app-mixin'
import UpdataImg from '@/components/file/updataImg'
import { addGoodsAPI, editGoodsAPI, getGoodsDetailAPI, getCategoryLinkAPI } from '@/request/api/physicalCard'
import { number } from 'echarts'

export default {
  mixins: [baseMixin],
  components: { draggable, UpdataImg, FooterToolBar },
  data() {
    return {
      isAdd: true,
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      draggable: {
        coverBtn: true,
        detailBtn: true
      },
      form: {
        id: +this.$route.params.productId !== 0 ? this.$route.params.productId : '',
        name:'',
        coverImg: '',
        description: '',
        headImg: [],
        detailsImg: [],
        categoryIdOne: 0,
        priceType: '0',
        stock: 0,
        state: 1,
        rank: '',
        virtual: 0,
        saleType: 1,
        required_price: '',
        categoryId: 0
      },
      firstTypes: [],
      secondTypes: [],
      rules: {
        name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          { max: 32, message: "最多输入32个字符", trigger: "blur" },
        ],
        coverImg: [{ required: true, message: "请上传商品列表图", trigger: ['change', 'blur']}],
        description: [
          { required: true, message: "请输入商品描述", trigger: "blur" },
          { max: 200, message: "最多输入200个字符", trigger: "blur" },
        ],
        headImg: [
          { required: true, message: "请上传商品头图", type:'array', trigger: ['change', 'blur']},
        ],
        detailsImg: [{ required: true, message: "请上传封面图", type:'array', trigger: ['change', 'blur']}],
        categoryId: [{ required: true, type: 'number', min:1, message: "请选择商品分类", trigger: ['change', 'blur']}],
        required_price: [
          // { required: true, message: "请设置商品价格", trigger: ['change', 'blur']},
          { validator: this.validatePrice, trigger: ['change', 'blur']},
        ],
        stock: [{ required: true, message: "请设置商品库存", type: 'number', min: 1, trigger: ['change', 'blur']}],
        rank: [{ required: true, message: "请设置商品排序", trigger: ['change', 'blur']}]
      },
      saleTypeObj: {
        '1': {
          price: ''
        },
        '2': {
         creditPrice: ''
        },
        '3': {
          price: '',
          creditPrice: ''
        }
      },
      changeType: false
    }
  },
  async created() {
    if(+this.$route.params.productId !== 0){
      this.isAdd = false
      // 获取详情
      await this.getDetail()
    }
    this.getCategoryLink()
  },
  methods: {
    validatePrice(rule, value, callback) {
      const isCreditPrice = this.saleTypeObj[this.form.saleType]?.creditPrice
      if (!this.form.required_price) {
        callback(new Error('请输入商品价格'))
        return
      } else if (isCreditPrice && parseInt(this.form.creditPrice, 10)!== this.form.creditPrice) {
        callback(new Error('积分只能设置整数'))
      } else {
        callback()
      }
    },
    handleCategoryChange(id) {
      this.changeType = true
      this.getCategoryLink(id)
    },
    async getCategoryLink(id = 0) {
      const params = {
        parentId: id
      }
      const { code, data } = await getCategoryLinkAPI(params)
      if (code !== 200) return
      if (id === 0) {
        this.firstTypes = data
        const categoryIdOne = this.isAdd ? data[0].id : this.form.categoryIdOne
        this.form.categoryIdOne = categoryIdOne
        this.getCategoryLink(categoryIdOne)
      } else {
        this.secondTypes = data
        if (data.length === 0) {
          this.form.categoryId = 0
          return
        }
        if (this.isAdd || this.changeType)  this.form.categoryId = data[0].id
      }
    },
    async	getDetail() {
      const params = {
        id: +this.form.id
      }
      const { code, data } = await getGoodsDetailAPI(params)
      if (code !== 200) return
      Object.assign(this.form, data)
      this.saleTypeObj[this.form.saleType].creditPrice = this.form.creditPrice
      this.saleTypeObj[this.form.saleType].price = this.form.price
      this.changeSaleType()
    },
    // 保存的按钮
    handleSave() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        if (+this.form.saleType === 1) this.form.creditPrice = 0
        if (+this.form.saleType === 2) this.form.price = 0.00
        this.isAdd ? await this.add() : await this.edit()
      })
    },
    async	add() {
      delete this.form.id
      const params = {
        ...this.form
      }
      const { code } = await addGoodsAPI(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.$router.push('/physicalCard/physicalGoods')
    },
    async	edit() {
      const params = {
        ...this.form
      }
      const { code } = await editGoodsAPI(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.$router.push('/physicalCard/physicalGoods')
    },
    changeSaleType(e) {
      const key =  this.form.saleType
      this.form.price = this.saleTypeObj[key]?.price
      this.form.creditPrice = this.saleTypeObj[key]?.creditPrice
      this.form.required_price = this.form.price || this.form.creditPrice
    }
  }
};
</script>
<style lang="less" scoped>
.listClass{
  width: 102px;
  height: 102px;
  border: 1px solid rgba(9, 9, 9, 0.07);
  padding: 5px;
  margin-right: 15px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.listBox{
  display: flex;
  >div{
    display: flex;
  }
}
.sale-radio-group {
  display: flex;
  flex-direction: column;
  .radio-item {
    margin-top: 10px;
  }
  .label-text {
    display: inline-block;
    width: 150px;
  }
}
</style>